import React from "react"
import DownloadCog from "../components/downloadCog"

const Downloads = (props) =>
  props.downloads.map((download, i) => {
    if (download != null) {
      const url = download.downloadFile ? download.downloadFile.guid : ""
      return (
        <li key={i} className="download">
          <h3 className="download__title">
            <a href={url}>{download.downloadTitle}</a>
          </h3>
          <a className="download__cog" href={url}>
            <DownloadCog />
          </a>
        </li>
      )
    } else {
      return false
    }
  })

export default Downloads
