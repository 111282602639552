import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import Downloads from "../components/downloads"
import Features from "../components/features"

class DownloadsTemplate extends Component {
  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      pageId = currentPage.parentId ? currentPage.id : currentPage.parentId,
      pageTitle = currentPage.parentId ? currentPage.title : false,
      introTitle =
        currentPage.introduction !== null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction !== null ? currentPage.introduction.intro : "",
      downloads =
        currentPage.downloads !== null ? currentPage.downloads.downloads : "",
      seoTitle = currentPage.title,
      seoDesc = ""

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageId={pageId} pageTitle={pageTitle} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <h3 className="section__title">
                    <span>Click to download</span>
                  </h3>
                  <ul className="downloads">
                    {downloads ? <Downloads downloads={downloads} /> : null}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default DownloadsTemplate

export const downloadsTemplateQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          parentId
          introduction {
            introTitle
            intro
          }
          downloads {
            downloads {
              downloadTitle
              downloadFile {
                guid
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
